<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()" v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>
    <div style="overflow-x: scroll;">
      <DataTable
        ref="dt"
        id="print"
        :value="list"
        :paginator="true"
        class="p-datatable-customers table table-striped"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        :loading="loading"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
        currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
        :scrollable="true"
        scrollDirection="both"
        style="width: 154vw;"
      >
        <template #header>
          <div class="table-header">
            قائمه العملاء
          </div>
        </template>
        <template #empty>
          لا يوجد بيانات
        </template>
        <template #loading>
          يتم تحميل البيانات. يرجي الانتظار...
        </template>
        <Column
          field="id"
          header="المسلسل"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              type="number"
              v-model="filters['id']"
              class="p-column-filter"
              placeholder="بحث بالمسلسل"
            />
          </template>
        </Column>
        <Column
          field="code"
          header="الكود"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              type="number"
              v-model="filters['code']"
              class="p-column-filter"
              placeholder="بحث بالكود"
            />
          </template>
        </Column>
        <Column
          field="name"
          header="اسم العميل"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['name']"
              class="p-column-filter"
              placeholder="بحث باسم العميل"
            />
          </template>
        </Column>

        <Column field="citiesId.name" header="المدينه" :sortable="true">
          <template #filter>
            <select
              class="form-select form-control p-column-filter"
              id="citiesId"
              name="citiesId"
              v-model="filters['citiesId.name']"
              placeholder="بحث بالمدينه"
            >
              <option :value="null">الكل </option>
              <option
                v-for="item of citiesList"
                :key="item.id"
                :value="item.name"
                >{{ item.name }}</option
              >
            </select>
          </template>
        </Column>
        <Column
          field="clientGroupsId.name"
          header="مجموعه العملاء"
          :sortable="true"
        >
          <template #filter>
            <select
              class="form-select form-control p-column-filter"
              id="clientGroupsId"
              name="clientGroupsId"
              v-model="filters['clientGroupsId.name']"
              placeholder="بحث بمجموعه العملاء"
            >
              <option :value="null">الكل </option>
              <option
                v-for="item of clientGroupsList"
                :key="item.id"
                :value="item.name"
                >{{ item.name }}</option
              >
            </select>
          </template>
        </Column>
        <Column
          field="managerName"
          header="المسئول"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['managerName']"
              class="p-column-filter"
              placeholder="بحث باسم المسئول"
            />
          </template>
        </Column>
        <Column
          field="managerPhone"
          header="جول المسئول"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['managerPhone']"
              class="p-column-filter"
              placeholder="بحث بجول المسئول"
            />
          </template>
        </Column>
        <Column
          field="phone"
          header="هاتف"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['phone']"
              class="p-column-filter"
              placeholder="بحث بالهاتف"
            />
          </template>
        </Column>
        <Column
          field="email"
          header="البريد الالكتروني"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['email']"
              class="p-column-filter"
              placeholder="بحث بالبريد الالكتروني"
            />
          </template>
        </Column>
        <Column
          field="userName"
          header="المستخدم"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['userName']"
              class="p-column-filter"
              placeholder="بحث بالمستخدم"
            />
          </template>
        </Column>
        <Column
          field="usersId.name"
          header="المندوب"
          :sortable="true"
        >
          <template #filter>
            <select
              class="form-select form-control p-column-filter"
              id="usersId"
              name="usersId"
              v-model="filters['usersId.name']"
              placeholder="بحث بالمندوب"
            >
              <option :value="null">الكل </option>
              <option
                v-for="item of usersList"
                :key="item.id"
                :value="item.name"
                >{{ item.name }}</option
              >
            </select>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      listx: [],
      selectedItems: [],
      loading: true,
      filters: {},
      citiesList: [],
      usersList: [],
      search: {
        start: null,
        end: null,
      },
      clientGroupsList: [],
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }

      // this.$http.post(`clients/search`, this.search).then(
      //   (response) => {
      //     this.loading = false;
      //     this.list = response.data;
      //   },
      //   (err) => {
      //     this.loading = false;
      //     this.$toast.add({
      //       severity: 'error',
      //       summary: 'هناك خطأ',
      //       detail: err.response.data.message,
      //       life: 3000,
      //     });
      //   },
      // );
    },
    getData() {
      this.$http.get(`clients`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.$http.get(`cities`).then((res) => {
      this.citiesList = res.data;
    });
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.$http.get(`clientGroups`).then((res) => {
      this.clientGroupsList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.col-form-label {
  text-align: start;
}
</style>
